import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Auto<i>dra</i>matic
        </p>
      </header>
    </div>
  );
}

export default App;
